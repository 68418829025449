export const cartTableFieldsDesktop = [
  {
    text: '',
    value: 'isCart',
    minWidth: {
      desktop: '60px',
      desktopLg: '60px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '60px',
    },
  },
  {
    text: 'POSITION',
    value: 'position',
    minWidth: {
      desktop: '75px',
      desktopLg: '75px',
    },
    maxWidth: {
      desktop: '75px',
      desktopLg: '75px',
    },
  },
  {
    text: 'ARTICLE-NR',
    value: 'articleNo',
    minWidth: {
      desktop: '88px',
      desktopLg: '110px',
    },
    maxWidth: {
      desktop: '88px',
      desktopLg: '110px',
    },
  },
  {
    text: 'TYRES-MANUFACTURER',
    value: 'manufacturer',
    minWidth: {
      desktop: '100px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '100px',
      desktopLg: '130px',
    },
  },
  {
    text: 'DESCRIPTION',
    value: 'description',
    minWidth: {
      desktop: '300px',
      desktopLg: '300px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'STOCK',
    value: 'stock',
    flexJustify: 'justify-center',
    minWidth: {
      desktop: '45px',
      desktopLg: '45px',
    },
    maxWidth: {
      desktop: '45px',
      desktopLg: '45px',
    },
  },
  {
    text: 'UNIT-PRICE',
    value: 'unitPrice',
    sortable: true,
    minWidth: {
      desktop: '65px',
      desktopLg: '65px',
    },
    maxWidth: {
      desktop: '85px',
      desktopLg: '85px',
    },
  },
  {
    text: 'TOTAL',
    value: 'totalPrice',
    sortable: true,
    minWidth: {
      desktop: '65px',
      desktopLg: '65px',
    },
    maxWidth: {
      desktop: '85px',
      desktopLg: '85px',
    },
  },
  {
    text: 'COMMISSION',
    value: 'commission',
    minWidth: {
      desktop: '130px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '180px',
      desktopLg: '200px',
    },
  },
  {
    text: 'QUANTITY',
    value: 'quantity',
    flexJustify: 'justify-center',
    minWidth: {
      desktop: '45px',
      desktopLg: '45px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '60px',
    },
  },
  {
    text: '',
    value: 'delete',
    flexJustify: 'justify-end',
    minWidth: {
      desktop: '108px',
      desktopLg: '108px',
    },
    maxWidth: {
      desktop: '108px',
      desktopLg: '108px',
    },
  },
];

export const cartTableFieldsMobile = [
  {
    text: '',
    value: 'isCart',
    minWidth: {
      desktop: '60px',
      desktopLg: '60px',
    },
    maxWidth: {
      desktop: '60px',
      desktopLg: '60px',
    },
  },
  {
    text: 'POSITION',
    value: 'position',
    minWidth: {
      desktop: '50px',
      desktopLg: '60px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '80px',
    },
  },
  {
    text: 'TYRES-MANUFACTURER',
    value: 'manufacturer',
    minWidth: {
      desktop: '100px',
      desktopLg: '100px',
    },
    maxWidth: {
      desktop: '100px',
      desktopLg: '100px',
    },
  },
  {
    text: 'ARTICLE-NR-DESCRIPTION',
    value: 'articleNo',
    minWidth: {
      desktop: '88px',
      desktopLg: '88px',
    },
    maxWidth: {
      desktop: '88px',
      desktopLg: '100px',
    },
    colSpan: 'col-span-8',
  },
  {
    text: 'STOCK',
    value: 'stock',
    align: 'text-center',
    minWidth: {
      desktop: '28px',
      desktopLg: '28px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '28px',
    },
  },
  {
    text: 'UNIT-PRICE',
    value: 'unitPrice',
    align: 'text-right',
    flexJustify: 'justify-end',
    sortable: true,
    minWidth: {
      desktop: '52px',
      desktopLg: '52px',
    },
    maxWidth: {
      desktop: '70px',
      desktopLg: '70px',
    },
    colSpan: 'col-span-2',
  },
  {
    text: 'TOTAL',
    value: 'totalPrice',
    align: 'text-right',
    flexJustify: 'justify-end',
    sortable: true,
    minWidth: {
      desktop: '52px',
      desktopLg: '52px',
    },
    maxWidth: {
      desktop: '70px',
      desktopLg: '70px',
    },
    colSpan: 'col-span-2',
  },
];

export const cartTableFieldsMobileMoreInfo = [
  {
    // col,
    text: '',
    value: 'emptycol1',
  },
  {
    text: 'COMMISSION',
    value: 'commission',
    minWidth: {
      desktop: '130px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '180px',
      desktopLg: '200px',
    },
    colSpan: 'col-start-2 row-start-2 col-span-9',
  },
  {
    // col,
    text: '',
    value: 'emptycol2',
  },
  {
    text: 'QUANTITY',
    value: 'quantity',
    align: 'text-center',
    minWidth: {
      desktop: '130px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '180px',
      desktopLg: '200px',
    },
    colSpan: 'col-start-12 row-start-2 col-span-3',
  },
  {
    text: '',
    value: 'delete',
    align: 'text-right',
    minWidth: {
      desktop: '108px',
      desktopLg: '108px',
    },
    maxWidth: {
      desktop: '108px',
      desktopLg: '108px',
    },
    colSpan: 'col-span-2',
  },
];

export const cartRecommendTableFieldsDesktop = [
  {
    text: 'IMAGE',
    value: 'image',
    minWidth: {
      desktop: '70px',
      desktopLg: '70px',
    },
    maxWidth: {
      desktop: '70px',
      desktopLg: '88px',
    },
  },
  {
    text: 'GROUP-ID',
    value: 'groupId',
    minWidth: {
      desktop: '88px',
      desktopLg: '88px',
    },
    maxWidth: {
      desktop: '88px',
      desktopLg: '100px',
    },
  },
  {
    text: 'TYRES-MANUFACTURER',
    value: 'manufacturer',
    minWidth: {
      desktop: '88px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '88px',
      desktopLg: '130px',
    },
  },
  {
    text: 'DESCRIPTION',
    value: 'description',
    minWidth: {
      desktop: '300px',
      desktopLg: '300px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'STOCK',
    value: 'stock',
    flexJustify: 'justify-center',
    minWidth: {
      desktop: '45px',
      desktopLg: '45px',
    },
    maxWidth: {
      desktop: '45px',
      desktopLg: '45px',
    },
  },
  {
    text: 'UNIT-PRICE',
    value: 'unitPrice',
    sortable: true,
    minWidth: {
      desktop: '65px',
      desktopLg: '65px',
    },
    maxWidth: {
      desktop: '85px',
      desktopLg: '85px',
    },
  },
  {
    text: 'COMMISSION',
    value: 'commission',
    minWidth: {
      desktop: '130px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '180px',
      desktopLg: '200px',
    },
  },
  {
    text: 'QUANTITY',
    value: 'quantity',
    flexJustify: 'justify-center',
    minWidth: {
      desktop: '45px',
      desktopLg: '45px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '60px',
    },
  },
  {
    text: '',
    value: 'action',
    flexJustify: 'justify-end',
    minWidth: {
      desktop: '108px',
      desktopLg: '108px',
    },
    maxWidth: {
      desktop: '108px',
      desktopLg: '108px',
    },
  },
];

export const cartRecommendTableFieldsMobile = [
  {
    text: 'IMAGE',
    value: 'image',
    minWidth: {
      desktop: '88px',
      desktopLg: '88px',
    },
    maxWidth: {
      desktop: '88px',
      desktopLg: '88px',
    },
  },
  {
    text: 'TYRES-MANUFACTURER',
    value: 'manufacturer',
    minWidth: {
      desktop: '100px',
      desktopLg: '100px',
    },
    maxWidth: {
      desktop: '100px',
      desktopLg: '100px',
    },
    colSpan: 'col-span-2',
  },
  {
    text: 'GROUP-ID-DESCRIPTION',
    value: 'groupId',
    minWidth: {
      desktop: '88px',
      desktopLg: '88px',
    },
    maxWidth: {
      desktop: '88px',
      desktopLg: '88px',
    },
    colSpan: 'col-span-8',
  },
  {
    text: 'STOCK',
    value: 'stock',
    align: 'text-center',
    minWidth: {
      desktop: '28px',
      desktopLg: '28px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '28px',
    },
  },
  {
    text: 'UNIT-PRICE',
    value: 'unitPrice',
    align: 'text-right',
    flexJustify: 'justify-end',
    sortable: true,
    minWidth: {
      desktop: '52px',
      desktopLg: '52px',
    },
    maxWidth: {
      desktop: '70px',
      desktopLg: '70px',
    },
    colSpan: 'col-span-2',
  },
];

export const cartRecommendTableFieldsMobileMoreInfo = [
  {
    // col,
    text: '',
    value: 'emptycol1',
  },
  {
    text: 'COMMISSION',
    value: 'commission',
    minWidth: {
      desktop: '130px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '180px',
      desktopLg: '200px',
    },
    colSpan: 'col-start-2 row-start-2 col-span-9',
  },
  {
    // col,
    text: '',
    value: 'emptycol2',
  },
  {
    text: 'QUANTITY',
    value: 'quantity',
    align: 'text-center',
    minWidth: {
      desktop: '130px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '180px',
      desktopLg: '200px',
    },
    colSpan: 'col-start-12 row-start-2 col-span-3',
  },
  {
    text: '',
    value: 'action',
    align: 'text-right',
    minWidth: {
      desktop: '108px',
      desktopLg: '108px',
    },
    maxWidth: {
      desktop: '108px',
      desktopLg: '108px',
    },
    colSpan: 'col-span-2',
  },
];

export const cartPdfTableFieldsDesktop = [
  {
    text: 'ARTICLE-NR',
    value: 'articleNo',
    minWidth: {
      desktop: '88px',
      desktopLg: '100px',
    },
    maxWidth: {
      desktop: '88px',
      desktopLg: '100px',
    },
  },
  {
    text: 'TYRES-MANUFACTURER',
    value: 'manufacturer',
    minWidth: {
      desktop: '100px',
      desktopLg: '110px',
    },
    maxWidth: {
      desktop: '100px',
      desktopLg: '110px',
    },
  },
  {
    text: 'DESCRIPTION',
    value: 'description',
    minWidth: {
      desktop: '180px',
      desktopLg: '180px',
    },
    maxWidth: {
      desktop: '1fr',
      desktopLg: '1fr',
    },
  },
  {
    text: 'STOCK',
    value: 'stock',
    flexJustify: 'justify-center',
    minWidth: {
      desktop: '45px',
      desktopLg: '45px',
    },
    maxWidth: {
      desktop: '45px',
      desktopLg: '45px',
    },
  },
  {
    text: 'VK',
    value: 'salePrice',
    sortable: true,
    minWidth: {
      desktop: '65px',
      desktopLg: '65px',
    },
    maxWidth: {
      desktop: '85px',
      desktopLg: '85px',
    },
  },
  {
    text: 'UNIT-PRICE',
    value: 'unitPrice',
    sortable: true,
    minWidth: {
      desktop: '65px',
      desktopLg: '65px',
    },
    maxWidth: {
      desktop: '85px',
      desktopLg: '85px',
    },
  },
  {
    text: 'TOTAL',
    value: 'totalPrice',
    sortable: true,
    minWidth: {
      desktop: '65px',
      desktopLg: '65px',
    },
    maxWidth: {
      desktop: '85px',
      desktopLg: '85px',
    },
  },
  {
    text: 'COMMISSION',
    value: 'commission',
    minWidth: {
      desktop: '120px',
      desktopLg: '120px',
    },
    maxWidth: {
      desktop: '160px',
      desktopLg: '180px',
    },
  },
  {
    text: 'QUANTITY',
    value: 'quantity',
    flexJustify: 'justify-center',
    minWidth: {
      desktop: '45px',
      desktopLg: '45px',
    },
    maxWidth: {
      desktop: '50px',
      desktopLg: '60px',
    },
  },
];

export const cartPdfTableFieldsMobile = [
  {
    text: 'TYRES-MANUFACTURER',
    value: 'manufacturer',
    minWidth: {
      desktop: '100px',
      desktopLg: '100px',
    },
    maxWidth: {
      desktop: '130px',
      desktopLg: '130px',
    },
    colSpan: 'col-start-2 col-span-2',
  },
  {
    text: 'ARTICLE-NR-DESCRIPTION',
    value: 'articleNo',
    minWidth: {
      desktop: '88px',
      desktopLg: '88px',
    },
    maxWidth: {
      desktop: '88px',
      desktopLg: '80px',
    },
    colSpan: 'col-span-7',
  },
  {
    text: 'STOCK',
    value: 'stock',
    align: 'text-center',
    minWidth: {
      desktop: '28px',
      desktopLg: '28px',
    },
    maxWidth: {
      desktop: '28px',
      desktopLg: '28px',
    },
  },
  {
    text: 'UNIT-PRICE',
    value: 'tabletUnitPrice',
    align: 'text-right',
    flexJustify: 'justify-end',
    sortable: true,
    minWidth: {
      desktop: '52px',
      desktopLg: '52px',
    },
    maxWidth: {
      desktop: '70px',
      desktopLg: '70px',
    },
    colSpan: 'col-span-2',
  },
  {
    text: 'TOTAL',
    value: 'tabletTotalPrice',
    align: 'text-right',
    flexJustify: 'justify-end',
    sortable: true,
    minWidth: {
      desktop: '52px',
      desktopLg: '52px',
    },
    maxWidth: {
      desktop: '70px',
      desktopLg: '70px',
    },
    colSpan: 'col-span-2',
  },
];

export const cartPdfTableFieldsMobileMoreInfo = [
  {
    text: 'COMMISSION',
    value: 'commission',
    minWidth: {
      desktop: '130px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '180px',
      desktopLg: '200px',
    },
    colSpan: 'col-start-2 row-start-2 col-span-10',
  },
  {
    text: 'VK',
    value: 'tabletSalePrice',
    align: 'text-right',
    minWidth: {
      desktop: '130px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '180px',
      desktopLg: '200px',
    },
    colSpan: 'col-start-12 row-start-2 col-span-2',
  },
  {
    text: 'QUANTITY',
    value: 'tabletQuantity',
    align: 'text-right',
    minWidth: {
      desktop: '130px',
      desktopLg: '130px',
    },
    maxWidth: {
      desktop: '180px',
      desktopLg: '200px',
    },
    colSpan: 'col-start-14 row-start-2 col-span-2',
  },
  // {
  //   // col,
  //   text: '',
  //   value: 'emptycol1',
  //   colSpan: 'col-start-14 row-start-2 col-span-2',
  // },
];

export const differingAddressCostPosition = {
  id: 0,
  articleNo: '9900000634',
  quantity: 1,
  article: {
    price: {
      purchasing: 3.5,
    },
  },
};

export const HIDDEN_SHIPPING_TYPES = [42, 43];